import React, { useState } from "react"
import AlgoliaSearch from 'algoliasearch'
import allProducts from '../lib/products'

const googleMapsClient = require('@google/maps').createClient({
  key: 'AIzaSyCAZURaohRkuX1N02erfk0ZQW-uqnBBdhw'
});


const client = AlgoliaSearch(process.env.GATSBY_ALGOLIA_DEFAULT_APP_ID, process.env.GATSBY_ALGOLIA_DEFAULT_API_KEY)
const storesIndex = client.initIndex('Stores')
const restaurantsIndex = client.initIndex('Restaurants')

const LocatorState = React.createContext()
const { Provider, Consumer } = LocatorState

const gm = (restaurant) => {
  return new Promise((resolve, reject) => {
    googleMapsClient.geocode({
      address: `${restaurant.name}, ${restaurant.address.lineOne}, ${restaurant.address.city}, ${restaurant.address.state}, ${restaurant.address.zipCode}`
    }, async function(err, response) {
      if (!err) {
        googleMapsClient.place({placeid: restaurant.gid}, function(err, response) {
          console.log('PID')
          if (!err) {
            resolve(response.json.result)
          } else {
            reject(err)
          }
        })
      } else {
        console.error("Error fetching geocode:", err)
        reject(err)
      }
    })
  })
}

export const storage = () => {
  if (!typeof window.localStorage === "undefined") return window.localStorage;
  else if (!typeof localStorage === "undefined") return localStorage;
  else return false;
};

class Locator extends React.Component {

  state = {
    properties: [],
    latlng: null,
    query: null,
    selected: null,
    isLoading: true,
    locationDenied: false,
    selectedProducts: [],
    algoliaQuery: '',
    hidePropertiesList: false,
    allProducts: allProducts,
    locatorType: null,
    filterRestaurantsBy: 'products',
    country: 'US'
  }

  findStores = (props) => {
    if (!props.latlng) return;
    const queryObject = {
      aroundLatLng: `${props.latlng.lat}, ${props.latlng.lng}`,
      aroundRadius: 40233,
      hitsPerPage: 1000,
      facets: ['products.name'],
      distinct: true,
    }

    if (this.state.algoliaQuery !== '') {
      queryObject.filters = this.state.algoliaQuery
    }

    const index = this.state.locatorType === 'stores' ? storesIndex : restaurantsIndex;
    return index.search(queryObject).then(async (res) => {
      if(props.fromGeoLoc === true && this.state.properties.length > 0) {
        return;
      }

      // Promise.all(res.hits.map(async (restaurant) => {
      //   console.log(restaurant)
      //   const r = await gm(restaurant)
      //   console.log(r)
      // }))
      // .then(() => {
      //   console.log('END')
      //   this.updateStores(res.hits, props.latlng, props.query)
      //   this.loading(false)
      // })
      // console.log('END')

      this.updateStores(res.hits, props.latlng, props.query)
      this.loading(false)
    }).catch(error => {
      this.loading(false)
      alert(error)
    })
  }

  setFilterRestaurantsBy = (by) => {
    this.setState({ filterRestaurantsBy: by })
  }

  setLocatorType = (type) => {
    this.setState({ locatorType: type })
  }

  selectProperty = (store) => {
    this.setState({ selected: store })
  }

  deselectProperty = () => {
    this.setState({ selected: null })
  }

  updateStores = (properties, latlng, query) => {
    // document.activeElement.blur();
    this.setState({ properties: properties, latlng: latlng, selected: null })
  }

  loading = (loading) => {
    this.setState({ isLoading: loading })
  }

  setLocationDenied = (locationDenied) => {
    this.setState({ locationDenied: locationDenied })
  }

  setCountry = (country) => {
    this.setState({ country: country})
  }

  filterByProducts = (products, props) => {
    let query = '';
    let firtsIteration = true;
    products.forEach((k, v) => {
      const or = firtsIteration ? '' : ' AND ';
      firtsIteration = false;
      if (v.type === 'dietary') {
        query += `${or}dietary.name:'${v.ID}'`
      } else {
        query += `${or}products.name:'${v.ID}'`
      }

    })
    const updateState = async () => {
      await this.setState({ algoliaQuery: query})
    }
    updateState().then(() => this.findStores(props));
  }

  hideProperties = (boll) => {
    this.setState({hidePropertiesList: boll})
  }

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          findStores: this.findStores,
          allStores: this.allStores,
          selectProperty: this.selectProperty,
          deselectProperty: this.deselectProperty,
          loading: this.loading,
          setLocationDenied: this.setLocationDenied,
          filterByProducts: this.filterByProducts,
          hideProperties: this.hideProperties,
          setLocatorType: this.setLocatorType,
          setFilterRestaurantsBy: this.setFilterRestaurantsBy,
          setCountry: this.setCountry
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}

export default Locator

export const withLocator = Component => props => (
  <Consumer>{ context => <Component locator={context} {...props} /> }</Consumer>
)
