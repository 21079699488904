
import VegetarianIcon from '../images/dietaryIcons/vegetarian.png'
import VeganIcon from '../images/dietaryIcons/vegan.png'
import DairyFreeIcon from '../images/dietaryIcons/dairyFree.png'
import GlutenFreeIcon from '../images/dietaryIcons/glutenFree.png'
import GrainFreeIcon from '../images/dietaryIcons/grainFree.png'
import NonGmoIcon from '../images/dietaryIcons/nonGmo.png'
import SoyFreeIcon from '../images/dietaryIcons/soyFree.png'
import PaleoIcon from '../images/dietaryIcons/paleo.png'
import Whole30ApprovedIcon from '../images/dietaryIcons/whole30approved.png'


const dietary = [
  {
    "ID": "Vegetarian",
    "Name": "Vegetarian",
    "Image_Url": VegetarianIcon,
    "type": "dietary"
  },
  {
    "ID": "Vegan",
    "Name": "Vegan",
    "Image_Url": VeganIcon,
    "type": "dietary"
  },
  {
    "ID": "Dairy-Free",
    "Name": "Dairy Free Friendly",
    "Image_Url": DairyFreeIcon,
    "type": "dietary"
  },
  {
    "ID": "Gluten-Free",
    "Name": "Gluten Free Friendly",
    "Image_Url": GlutenFreeIcon,
    "type": "dietary"
  },
  {
    "ID": "Grain-Free",
    "Name": "Grain Free Friendly",
    "Image_Url": GrainFreeIcon,
    "type": "dietary"
  },
  {
    "ID": "Non-GMO",
    "Name": "Non-GMO Friendly",
    "Image_Url": NonGmoIcon,
    "type": "dietary"
  },
  {
    "ID": "Soy-Free",
    "Name": "Soy Free Friendly",
    "Image_Url": SoyFreeIcon,
    "type": "dietary"
  },
  {
    "ID": "Paleo-Free",
    "Name": "Paleo Friendly",
    "Image_Url": PaleoIcon,
    "type": "dietary"
  },
  {
    "ID": "Whole-30-Approved",
    "Name": "Whole 30 Approved",
    "Image_Url": Whole30ApprovedIcon,
    "type": "dietary"
  },
]

/*
Siete - UPCs
https://docs.google.com/spreadsheets/d/1XdTpAVT_oTlc7LelwPceQI5eQBJl8Te2E7_GgNEaLIc
*/

const products = [
  /*
  {
    "ID": "Fuego-Tortilla-Chips",
    "Product": "Fuego Tortilla Chips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Fuego Tortilla Chips (4OZ).png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/fuego-grain-free-tortilla-chips-4oz-6-bags",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-fuego-grain-free-tortilla-chips.html",
  },
  */
  /*
  {
    "ID": "Sal-Y-Limon-Tortilla-Chips",
    "Product": "Sal Y Limon Tortilla Chips",
    "Category": "Tortilla Chips",
    "UPC": 851769007072,
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Sal Y Limon Tortilla Chips (4OZ).png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/sal-y-limon-grain-free-tortilla-chips-4oz-6-bags",
  },
  */
  /*
  {
    "ID": "Ranch-Tortilla-Chips",
    "Product": "Ranch Tortilla Chips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Ranch Tortilla Chips (4OZ).png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/ranch-grain-free-tortilla-chips-4oz-6-bags",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-ranch-grain-free-tortilla-chips.html",
  },
  */
  {
    "ID": "Lime-Tortilla-Chips",
    "Product": "Lime Tortilla Chips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Lime Tortilla Chips (5OZ).png",
    "Image_Url_CA": "https://storage.googleapis.com/store_locator_photos/CANADA/Lime-Tortilla-Chips-CA.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/lime-grain-free-tortilla-chips",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-lime-grain-free-tortilla-chips-142g.html",
  },
  /*
  {
    "ID": "Lime-Tortilla-Chips-Ca",
    "Product": "Lime Tortilla Chips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Lime-Tortilla-Chips-Ca.png",
    "Online_Store_Url": "",
  },
  */
  {
    "ID": "No-Salt-Tortilla-Chips",
    "Product": "No Salt Tortilla Chips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/No Salt Tortilla Chips (5OZ).png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/no-salt-grain-free-tortilla-chips-5oz-6-bags",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-no-salt-grain-free-tortilla-chips-142g.html",
  },
  {
    "ID": "Sea-Salt-Tortilla-Chips",
    "Product": "Sea Salt Tortilla Chips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Sea Salt Tortilla Chips (5OZ).png",
    "Image_Url_CA": "https://storage.googleapis.com/store_locator_photos/CANADA/Sea-Salt-Tortilla-Chips-CA.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/sea-salt-grain-free-tortilla-chips",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-sea-salt-grain-free-tortilla-chips-142g.html",
  },
  /*
  {
    "ID": "Sea-Salt-Tortilla-Chips-Ca",
    "Product": "Sea Salt Tortilla Chips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Sea-Salt-Tortilla-Chips-Ca.png",
    "Online_Store_Url": "",
  },
  */
  {
    "ID": "Nacho-Tortilla-Chips",
    "Product": "Nacho Tortilla Chips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Nacho Tortilla Chips (5OZ).png",
    "Image_Url_CA": "https://storage.googleapis.com/store_locator_photos/CANADA/Nacho-Tortilla-Chips-CA.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/nacho-grain-free-tortilla-chips",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-nacho-grain-free-tortilla-chips-142g.html",
  },
  /*
  {
    "ID": "Nacho-Tortilla-Chips-Ca",
    "Product": "Nacho Tortilla Chips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Nacho-Tortilla-Chips-Ca.png",
    "Online_Store_Url": "",
  },
  */
  {
    "ID": "Sea-Salt-Tortilla-Chips-Familia-Pack",
    "Product": "Sea Salt Chips Familia Pack",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Sea Salt Tortilla Chips Familia Pack.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/familia-pack-sea-salt-tortilla-chips-1oz-3-packs-18-bags",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Chipotle-BBQ-Tortilla-Chips",
    "Product": "Chipotle BBQ Tortilla Chips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Chipotle BBQ Tortilla Chips (4OZ).png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/chipotle-barbecue-tortilla-chip-4oz-6-bags",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-chipotle-bbq-grain-free-tortilla-chips-113g.html",
  },
  {
    "ID": "Jalapeño-Lime-Tortilla-Chips",
    "Product": "Jalapeño Lime Tortilla Chips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Jalapeno Lime Tortilla Chips (4OZ).png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/jalapeno-lime-tortilla-chips-4oz-6-bags",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-jalape-o-lime-grain-free-tortilla-chips-113g.html",
  },
  {
    "ID": "Dip-Tortilla-Chips",
    "Product": "Dip Chips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Dip Tortilla Chips (5OZ).png",
    "Image_Url_CA": "https://storage.googleapis.com/store_locator_photos/CANADA/Dip-Tortilla-Chips-CA.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/dip-chip",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-dippable-round-grain-free-tortilla-chips-142g.html",
  },
  {
    "ID": "Lime-Tortilla-Chips-Familia-Pack",
    "Product": "Lime Chips Familia Pack",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Lime Tortilla Chips Familia Pack.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/familia-pack-lime-tortilla-chips-1oz",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "BUÑUELOS",
    "Product": "BUÑUELOS",
    "Category": "Seasonal",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/BUNUELOS.png",
    "Online_Store_Url": "",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Churro-Strips",
    "Product": "Churro Strips",
    "Category": "Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Churro Strips.png",
    "Image_Url_CA": "https://storage.googleapis.com/store_locator_photos/CANADA/Churro-Strips-CA.png",
    "Online_Store_Url": "https://sietefoods.com/products/churro-strips-5-oz-6-bags",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-grain-free-cinnamon-chips-churro-strips-142g.html",
  },
  {
    "ID": "Sea-Salt-Potato-Chips",
    "Product": "Sea Salt Potato Chips",
    "Category": "Potato Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Sea Salt Potato Chips.png",
    "Online_Store_Url": "https://sietefoods.com/collections/potato-chips/products/sea-salt-potato-chips-6-bags",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-grain-free-kettle-cooked-potato-chips-sea-salt-156g.html",
  },
  {
    "ID": "Serrano-&-Vinegar-Potato-Chips",
    "Product": "Hint of Serrano, Sea Salt And Vinegar Potato Chips",
    "Category": "Potato Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Serrano, Ss & Vinegar Potato Chips.png",
    "Online_Store_Url": "https://sietefoods.com/collections/potato-chips/products/sea-salt-vinegar-serrano-chips",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-grain-free-kettle-cooked-potato-chips-hint-of-serrano-sea-salt-vinegar-156g.html",
  },
  {
    "ID": "Fuego-Potato-Chips",
    "Product": "Fuego Potato Chips",
    "Category": "Potato Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Fuego Potato Chips.png",
    "Online_Store_Url": "https://sietefoods.com/collections/potato-chips/products/fuego-potato-chips",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-grain-free-kettle-cooked-potato-chips-fuego-156g.html",
  },
  {
    "ID": "Chipotle-Bbq-Potato-Chips",
    "Product": "Chipotle Bbq Potato Chips",
    "Category": "Potato Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Chipotle Bbq Potato Chips.png",
    "Online_Store_Url": "https://sietefoods.com/collections/potato-chips/products/chipotle-bbq-potato-chips",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-grain-free-kettle-cooked-potato-chips-chipotle-bbq-156g.html",
  },
  {
    "ID": "Queso-Potato-Chips",
    "Product": "Queso Potato Chips",
    "Category": "Potato Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Queso Potato Chips.png",
    "Online_Store_Url": "https://sietefoods.com/collections/potato-chips/products/queso-kettle-cooked-potato-chips-6-bags",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Chile-Lime-Potato-Chips",
    "Product": "Chile Lime Potato Chips",
    "Category": "Potato Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Chile Lime Potato Chips.png",
    "Online_Store_Url": "",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Sea-Salt-Potato-Chips-Single-Serve",
    "Product": "Sea Salt Kettle Cooked Potato Chips Single Serve",
    "Category": "Snack Size",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Sea-Salt-Potato-Chips-Single-Serve.png",
    "Online_Store_Url": "https://sietefoods.com/collections/potato-chips/products/sea-salt-kettle-cooked-potato-chips-1-5oz",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Sea-Salt-Potato-Chips-Green-Goddess",
    "Product": "Poblano Green Goddess Ranch Kettle Cooked Potato Chips",
    "Category": "Snack Size",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Sea-Salt-Potato-Chips-Green-Goddess.png",
    "Online_Store_Url": "",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Chipotle-BBQ-Potato-Chips-Single-Serve",
    "Product": "Chipotle BBQ Kettle Cooked Potato Chips Single Serve",
    "Category": "Snack Size",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Chipotle-BBQ-Potato-Chips-Single-Serve.png",
    "Online_Store_Url": "https://sietefoods.com/collections/potato-chips/products/chipotle-bbq-kettle-cooked-potato-chips-1-5-oz",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Serrano Salt-Vinegar-Potato-Chips-Single-Serve",
    "Product": "Serrano Sea Salt & Vinegar Kettle Cooked Potato Chips Single Serve",
    "Category": "Snack Size",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Serrano Salt-Vinegar-Potato-Chips-Single-Serve.png",
    "Online_Store_Url": "https://sietefoods.com/collections/potato-chips/products/sea-salt-vinegar-kettle-cooked-potato-chips-1-5-oz",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Churro-Chips-Single-Serve",
    "Product": "Churro Tortilla Strip Chip Single Serve",
    "Category": "Snack Size",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Churro-Chips-Single-Serve.png",
    "Online_Store_Url": "https://sietefoods.com/collections/sweets/products/churro-strips-1-oz-24-bags",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Lime-Tortilla-Chips-Single-Serve",
    "Product": "Lime Tortilla Chips Single Serve",
    "Category": "Snack Size",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Lime-Tortilla-Chips-Single-Serve.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/lime-grain-free-tortilla-chips-1oz-24-bags",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Nacho-Tortilla-Chips-Single-Serve",
    "Product": "Nacho Tortilla Chips Single Serve",
    "Category": "Snack Size",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Nacho-Tortilla-Chips-Single-Serve.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/nacho-grain-free-tortilla-chips-1oz-1-case-24-bags",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Sea-Salt-Tortilla-Chips-Single-Serve",
    "Product": "Sea Salt Tortilla Chips Single Serve",
    "Category": "Snack Size",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Sea-Salt-Tortilla-Chips-Single-Serve.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortilla-chips/products/1oz-24-bags-sea-salt-grain-free-tortilla-chips",
    "Online_Store_Url_CA": "",
  },
  /*
  {
    "ID": "Sprouted-Bean-Dip",
    "Product": "Sprouted Bean Dip",
    "Category": "Dips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Sprouted Bean Dip.png",
    "Online_Store_Url": "https://sietefoods.com/collections/dips-and-sauces/products/bean-dip",
  },
  */
  // {
  //   "ID": "Spicy-Blanco-Cashew-Queso",
  //   "Product": "Spicy Blanco Cashew Queso",
  //   "Category": "Dips",
  //   "Image_Url": "https://storage.googleapis.com/store_locator_photos/Spicy Blanco Cashew Queso.png",
  //   "Online_Store_Url": "https://sietefoods.com/collections/dips-and-sauces/products/spicy-blanco-cashew-queso",
  //   "Online_Store_Url_CA": "",
  // },
  // {
  //   "ID": "Mild-Nacho-Cashew-Queso",
  //   "Product": "Mild Nacho Cashew Queso",
  //   "Category": "Dips",
  //   "Image_Url": "https://storage.googleapis.com/store_locator_photos/Mild Nacho Cashew Queso.png",
  //   "Online_Store_Url": "https://sietefoods.com/collections/dips-and-sauces/products/mild-nacho-cashew-queso",
  //   "Online_Store_Url_CA": "",
  // },
  // {
  //   "ID": "Traditional-Hot-Sauce",
  //   "Product": "Traditional Hot Sauce",
  //   "Category": "Sauces",
  //   "Image_Url": "https://storage.googleapis.com/store_locator_photos/Traditional Hot Sauce.png",
  //   "Online_Store_Url": "https://sietefoods.com/collections/hot-sauce/products/traditional-hot-sauce-4-bottles",
  //   "Online_Store_Url_CA": "https://naturamarket.ca/siete-traditional-hot-sauce-141g.html",
  // },
  // {
  //   "ID": "Chipotle-Hot-Sauce",
  //   "Product": "Chipotle Hot Sauce",
  //   "Category": "Sauces",
  //   "Image_Url": "https://storage.googleapis.com/store_locator_photos/Chipotle Hot Sauce.png",
  //   "Online_Store_Url": "https://sietefoods.com/collections/hot-sauce/products/chipotle-hot-sauce-4-bottles",
  //   "Online_Store_Url_CA": "https://naturamarket.ca/siete-chipotle-hot-sauce-141g.html",
  // },
  // {
  //   "ID": "Habanero-Hot-Sauce",
  //   "Product": "Habanero Hot Sauce",
  //   "Category": "Sauces",
  //   "Image_Url": "https://storage.googleapis.com/store_locator_photos/Habanero Hot Sauce.png",
  //   "Online_Store_Url": "https://sietefoods.com/collections/hot-sauce/products/habanero-hot-sauce-4-bottles",
  //   "Online_Store_Url_CA": "https://naturamarket.ca/siete-habanero-hot-sauce-141g.html",
  // },
  // {
  //   "ID": "Jalapeño-Hot-Sauce",
  //   "Product": "Jalapeño Hot Sauce",
  //   "Category": "Sauces",
  //   "Image_Url": "https://storage.googleapis.com/store_locator_photos/Jalapeno Hot Sauce.png",
  //   "Online_Store_Url": "https://sietefoods.com/collections/hot-sauce/products/jalapeno-hot-sauce-6-bottles",
  //   "Online_Store_Url_CA": "https://naturamarket.ca/siete-jalapeno-hot-sauce-141g.html",
  // },
  {
    "ID": "Green-Enchilada-Sauce",
    "Product": "Green Enchilada Sauce",
    "Category": "Enchilada Sauces",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Green Enchilada Sauce.png",
    "Online_Store_Url": "https://sietefoods.com/collections/dips-and-sauces/products/green-enchilada-sauce",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-green-enchilada-sauce-425g.html",
  },
  {
    "ID": "Red-Enchilada-Sauce",
    "Product": "Red Enchilada Sauce",
    "Category": "Enchilada Sauces",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Red Enchilada Sauce.png",
    "Online_Store_Url": "https://sietefoods.com/collections/dips-and-sauces/products/red-enchilada-sauce",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-red-enchilada-sauce-425g.html",
  },
  {
    "ID": "Almond-Flour-Tortillas",
    "Product": "Almond Flour Tortillas",
    "Category": "Tortillas",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Almond Flour Tortillas.png",
    "Image_Url_CA": "https://storage.googleapis.com/store_locator_photos/CANADA/Almond-Flour-Tortillas-CA.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortillas/products/6-pack-almond-flour-tortillas",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-almond-flour-tortillas-8-count-200g.html",
  },
  {
    "ID": "Cassava-Flour-Tortillas",
    "Product": "Cassava Flour Tortillas",
    "Category": "Tortillas",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Cassava Flour Tortillas.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortillas/products/six-packs-cassava-flour-tortillas",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-cassava-coconut-flour-tortillas-8-count-200g.html",
  },
  /*
  {
    "ID": "Cashew-Flour-Tortillas",
    "Product": "Cashew Flour Tortillas",
    "Category": "Tortillas",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Cashew Flour Tortillas.png",
    "Online_Store_Url": "",
    "Online_Store_Url_CA": "",
  },
  */
  /*
  {
    "ID": "Cassava-&-Chia-Tortillas",
    "Product": "Cassava & Chia Tortillas",
    "Category": "Tortillas",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Cassava & Chia Tortillas.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortillas/products/cassava-chia-tortillas-6-packs-1",
    "Online_Store_Url_CA": "",
  },
  */
  {
    "ID": "Chickpea-Flour-Tortillas",
    "Product": "Chickpea Flour Tortillas",
    "Category": "Tortillas",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Chickpea Flour Tortillas.png",
    "Online_Store_Url": "https://sietefoods.com/collections/tortillas/products/chickpea-flour-tortillas-6-packs",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Burrito-Size-Tortillas",
    "Product": "Burrito Size Tortillas",
    "Category": "Tortillas",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Burrito Size Tortillas.png",
    "Online_Store_Url": "",
    "Online_Store_Url_CA": "",
  },
  /*
  {
    "ID": "Club-Almond-Flour-Tortillas",
    "Product": "Club Almond Flour Tortillas",
    "Category": "Tortillas",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Club Almond Flour Tortillas.png",
    "Online_Store_Url": "",
  },
  */
  {
    "ID": "Mild-Taco-Seasoning",
    "Product": "Mild Taco Seasoning",
    "Category": "Seasoning",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Mild Taco Seasoning.png",
    "Image_Url_CA": "https://storage.googleapis.com/store_locator_photos/CANADA/Mild-Taco-Seasoning-CA.png",
    "Online_Store_Url": "https://sietefoods.com/collections/seasonings/products/mild-taco-seasoning-6-pack",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-paleo-taco-seasoning-mild-37g.html",
  },
  {
    "ID": "Spicy-Taco-Seasoning",
    "Product": "Spicy Taco Seasoning",
    "Category": "Seasoning",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Spicy Taco Seasoning.png",
    "Image_Url_CA": "https://storage.googleapis.com/store_locator_photos/CANADA/Spicy-Taco-Seasoning-CA.png",
    "Online_Store_Url": "https://sietefoods.com/collections/taco-shells/products/spicy-taco-seasoning",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-taco-seasoning-spicy-37g.html",
  },
  {
    "ID": "Carnitas-Seasoning",
    "Product": "Carnitas Seasoning",
    "Category": "Seasoning",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Carnitas Seasoning.png",
    "Online_Store_Url": "https://sietefoods.com/collections/seasonings-sauces/products/carnitas-seasoning-6-pack",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-carnitas-seasoning-slow-cooker-spice-36-7g.html",
  },
  {
    "ID": "Chorizo-Seasoning",
    "Product": "Chorizo Seasoning",
    "Category": "Seasoning",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Chorizo Seasoning.png",
    "Online_Store_Url": "https://sietefoods.com/collections/seasonings-sauces/products/chorizo-seasoning-6-pack",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-chorizo-seasoning-skillet-cooking-spice-39g.html",
  },
  {
    "ID": "Mexican-Wedding-Cookie",
    "Product": "Mexican Wedding Cookie",
    "Category": "Cookies",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Mexican Wedding Cookie.png",
    "Online_Store_Url": "https://sietefoods.com/collections/cookies/products/mexican-wedding-cookies",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-grain-free-cookies-mexican-wedding-127-8g.html",
  },
  {
    "ID": "Mexican-Shortbread-Cookie",
    "Product": "Mexican Shortbread Cookie",
    "Category": "Cookies",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Mexican Shortbread Cookie.png",
    "Online_Store_Url": "https://sietefoods.com/collections/cookies/products/mexican-shortbread-cookie",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-grain-free-cookies-mexican-shortbread-127-8g.html",
  },
  {
    "ID": "Mexican-Chocolate-Cookie",
    "Product": "Mexican Chocolate Cookie",
    "Category": "Cookies",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Mexican Chocolate Cookie.png",
    "Online_Store_Url": "https://sietefoods.com/collections/cookies/products/mexican-chocolate-cookies",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-grain-free-cookies-mexican-chocolate-127-8g.html",
  },
  {
    "ID": "Taco-Shells",
    "Product": "Taco Shells",
    "Category": "Taco Shells",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Taco Shells.png",
    "Image_Url_CA": "https://storage.googleapis.com/store_locator_photos/CANADA/Taco-Shells-CA.png",
    "Online_Store_Url": "https://sietefoods.com/products/taco-shell",
    "Online_Store_Url_CA": "https://naturamarket.ca/siete-grain-free-taco-shells-12-count.html",
  },
  {
    "ID": "Refried-Black-Beans",
    "Product": "Refried Black Beans",
    "Category": "Beans",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Refried-Black-Beans.png",
    "Online_Store_Url": "https://sietefoods.com/products/refried-black-beans",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Refried-Pinto-Beans",
    "Product": "Refried Pinto Beans",
    "Category": "Beans",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Refried-Pinto-Beans.png",
    "Online_Store_Url": "https://sietefoods.com/products/traditional-refried-pinto-beans",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Charro-Beans",
    "Product": "Charro Beans",
    "Category": "Beans",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Charro-Beans.png",
    "Online_Store_Url": "",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Refried-Ranchero-Beans",
    "Product": "Refried Ranchero Beans",
    "Category": "Beans",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Refried-Ranchero-Beans.png",
    "Online_Store_Url": "",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Chamoy-Botana-Sauce",
    "Product": "Chamoy Botana Sauce",
    "Category": "Botana Sauces",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Chamoy-Botana-Sauce.png",
    "Online_Store_Url": "",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Habanero-Botana-Sauce",
    "Product": "Habanero Botana Sauce",
    "Category": "Botana Sauces",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Habanero-Botana-Sauce.png",
    "Online_Store_Url": "",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Jalapeno-Botana-Sauce",
    "Product": "Jalapeno Botana Sauce",
    "Category": "Botana Sauces",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Jalapeno-Botana-Sauce.png",
    "Online_Store_Url": "",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Traditional-Botana-Sauce",
    "Product": "Traditional Botana Sauce",
    "Category": "Botana Sauces",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Traditional-Botana-Sauce.png",
    "Online_Store_Url": "",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Salt-Maíz-Totopos",
    "Product": "Sea Salt Maíz Totopos",
    "Category": "Corn Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Salt-Mai%CC%81z-Totopos.png",
    "Online_Store_Url": "https://sietefoods.com/collections/maiz-chips/products/maiz-sea-salt-totopos-corn-tortilla-chips-7-5oz",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Blue-Maíz-Totopos",
    "Product": "Blue Corn Maíz Totopos",
    "Category": "Corn Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Blue-Mai%CC%81z-Totopos.png",
    "Online_Store_Url": "https://sietefoods.com/collections/maiz-chips/products/maiz-blue-corn-totopos-tortilla-chips-7-5oz",
    "Online_Store_Url_CA": "",
  },
  {
    "ID": "Lime-Maíz-Totopos",
    "Product": "Lime Maíz Totopos",
    "Category": "Corn Tortilla Chips",
    "Image_Url": "https://storage.googleapis.com/store_locator_photos/Lime-Mai%CC%81z-Totopos.png",
    "Online_Store_Url": "https://sietefoods.com/collections/maiz-chips/products/maiz-lime-totopos-corn-tortilla-chips-7-5oz",
    "Online_Store_Url_CA": "",
  },
];


const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

const grouped = groupBy(products, product => product.Category);

export default {
  all: products,
  grouped: grouped,
  dietary: dietary
};
